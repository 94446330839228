export const trackTogglePanel = (
  isExpanded: boolean,
  panelName: string
): void => {
  const action = isExpanded ? 'expand' : 'collapse'
  gtag('event', `panel_${action}`, {
    panel_name: panelName
  })
}

export const trackLogin = (params: { method: string | undefined }): void => {
  gtag('event', 'login', {
    ...params
  })
}

export interface TooltipParams {
  /** Populated with icon name if tooltip was triggered by an icon. */
  icon?: string
  /** If triggered by a colored icon, this is the icon of the color. */
  color?: string
  /** Generic field to classify or differentiate the event from others with the same name. */
  type?: string
}

export const trackViewTooltip = (
  tooltipName: string,
  params: TooltipParams = {}
): void => {
  gtag(
    'event',
    'view_tooltip',
    Object.assign({ tooltip_name: tooltipName }, params)
  )
}

export const trackToggleAddOns = (shown: boolean): void => {
  gtag('event', `add_ons_${shown ? 'show' : 'hide'}`)
}
