





















































































































































































import { defineComponent, PropType } from '@vue/composition-api'

import IconsWithTooltips, { Icon } from '@/components/IconsWithTooltips.vue'
import {
  formatDateDistanceToNowIfRecent,
  formatDateShort
} from '@/components/mixins/dateFormatters'
import { OrderItemData } from '@/components/mixins/orderUtils'
import { pluralize } from '@/components/mixins/pluralize'
import OrderItemOverallStatusIcon from '@/components/OrderItemOverallStatusIcon.vue'
import Tooltip from '@/components/Tooltip.vue'
import { OrderItem, OrderItemOverallStatus } from '@/store/modules/types/order'

export default defineComponent({
  components: {
    IconsWithTooltips,
    OrderItemOverallStatusIcon,
    Tooltip
  },
  props: {
    orderItem: {
      type: Object as PropType<OrderItem>,
      required: true
    },
    orderItemData: {
      type: Object as PropType<OrderItemData>,
      required: true
    },
    overallStatus: {
      type: String as PropType<OrderItemOverallStatus>,
      required: true
    },
    showAddOns: {
      type: Boolean,
      required: true
    }
  },
  setup({ orderItem, orderItemData }) {
    const proof = orderItem.proof
    const salesforceProof = orderItemData.proof
    const bestRevision = proof?.revisions.find(
      (revision) => revision.revisionNumber === proof.bestRevisionNumber
    )
    const bestRevisionDefaultSurface = bestRevision?.surfaces.find(
      (surface) => !surface.isHidden
    )

    // Icons are grouped by color and importance.
    // Least important icons and ones without colors are added last.
    const icons: Icon[] = []
    const numShipments = orderItemData.shipments?.length || 0
    if (numShipments > 1) {
      icons.push({
        icon: '$redo',
        color: 'red',
        message: `Shipped ${pluralize(numShipments, 'time')}`
      })
    }
    if (salesforceProof?.express) {
      icons.push({
        icon: '$fast-forward',
        color: 'yellow darken-2',
        message: 'Express Approval (no designer review)'
      })
    }
    const numRevisions = salesforceProof?.revisions?.length || 0
    if (numRevisions > 1) {
      const numEdits = numRevisions - 1
      const daysDelayed = orderItemData.daysDelayedByApprovals
      icons.push({
        icon: '$pen-square',
        color: daysDelayed ? 'orange' : 'blue',
        // TODO: Provide info about the revisions
        message:
          `${pluralize(numEdits, 'edit')}` +
          (daysDelayed
            ? `, delayed order by ${pluralize(daysDelayed, 'business day')}`
            : ''),
        label: numEdits
      })
    }
    const numProofSpecialInstructions =
      salesforceProof?.specialInstructions?.length || 0
    if (numProofSpecialInstructions) {
      icons.push({
        icon: '$sticky-note',
        color: 'blue',
        message: pluralize(
          numProofSpecialInstructions,
          'proof special instruction'
        ),
        // Darken tooltip background to improve legibility, and for consistency with other special instruction tooltips
        tooltipColor: 'grey darken-4',
        // TODO: Make heading formatting consistent with customer and order special instructions
        component: 'SpecialInstructionListItems',
        data: {
          specialInstructions: salesforceProof?.specialInstructions,
          dark: true
        }
      })
    }
    // TODO: Calculate delay and consider if it should affected the adjusted promised ship date
    const revisionsCreatedAfterFirstApproval =
      salesforceProof?.revisions?.filter(
        (revision) =>
          salesforceProof.dateFirstApproved &&
          revision.createdDate &&
          salesforceProof.dateFirstApproved.getTime() <
            revision.createdDate.getTime()
      ) || []
    const numRevisionsAfterFirstApproval =
      revisionsCreatedAfterFirstApproval.length
    if (numRevisionsAfterFirstApproval) {
      icons.push({
        icon: '$comments',
        color: 'blue',
        message:
          pluralize(numRevisionsAfterFirstApproval, 'revision') +
          ' created after first customer approval'
      })
    }
    const hasAddOnTitleContaining = (title: string) =>
      !orderItemData.isAddOn &&
      orderItemData.addOns.some(
        (addOn) => addOn.item.shortTitle.indexOf(title) !== -1
      )
    if (hasAddOnTitleContaining('Double Thick')) {
      icons.push({
        icon: '$layer-plus',
        message: `Double Thick`
      })
    } else if (hasAddOnTitleContaining('Triple Thick')) {
      icons.push({
        icon: '$layer-group',
        message: `Triple Thick`
      })
    }
    // Uses rules from ShipmentAnalysis.cls Apex code from sfdc
    const formatEnumName = orderItem.item.format.enumName
    const hasCircle =
      formatEnumName.indexOf('CIRCLE_') !== -1 ||
      formatEnumName.indexOf('ORNAMENTS_') !== -1 ||
      formatEnumName.indexOf('COASTER_') !== -1 ||
      formatEnumName == 'MEMORY_GAME'
    const hasRoundCorners =
      !orderItemData.isAddOn &&
      orderItemData.addOns.some(
        (addOn) => addOn.item.shortTitle === 'Round Corners'
      )
    if (hasCircle || hasRoundCorners) {
      icons.push({
        icon: '$cut',
        message:
          (hasCircle ? 'Circle' : 'Round Corners') + ' (requires cutting)'
      })
    }
    if (orderItem.item.foilColor) {
      /**
       * Color swatched from our on-site foil icons
       */
      let color
      switch (orderItem.item.foilColor) {
        case 'GOLD':
          color = '#EAD27B'
          break
        case 'SILVER':
          color = '#D3D3D3'
          break
        case 'ROSE':
          color = '#D4A68F'
          break
      }
      icons.push({
        icon: '$sparkles',
        message: `Foil (${orderItem.item.foilColor.toLowerCase()})`,
        color
      })
    }
    // Put last so it sticks out if there's a similar shipped item
    if (orderItem.deliveryType === 'Mail & Message') {
      icons.push({
        // Also tried '$mailbox', but it's harder to identify at a small size
        icon: '$mail-bulk',
        message: 'Mailed For You'
      })
    } else if (orderItem.deliveryType === 'Address & Message') {
      icons.push({
        icon: '$address-card',
        message: 'Shipped To You with recipient addressing'
      })
    }

    return {
      get proofUrl() {
        return bestRevisionDefaultSurface
          ? 'https://paperculture.imgix.net' +
              bestRevisionDefaultSurface.proofUrl
          : null
      },
      get deliveryTypeDisplay() {
        switch (orderItem.deliveryType) {
          case 'Send to Self':
            return 'Shipped to Customer'
          case 'Address & Message':
            return 'Shipped with Recipient Addressing'
          case 'Mail & Message':
            return 'Mailed to Recipients'
          default:
            return '[Unknown Delivery Type]'
        }
      },
      actions: [
        // TODO: Implement inline UI to take specific actions
        // {
        //   title: 'Add Proof Special Instruction',
        //   link: 'https://www.youtube.com/watch?v=gSLIdT4EBlw'
        // }
      ],
      formatDateShort,
      formatDateDistanceToNowIfRecent,
      icons
    }
  }
})
