var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VListItem',[_c('VListItemContent',[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between order-item-content"},[_c('div',{staticClass:"order-item-summary"},[_c('VListItemTitle',[_c('OrderItemOverallStatusIcon',{attrs:{"status":_vm.overallStatus,"days-late":_vm.orderItemData.daysLate,"proof-last-modified-date":_vm.orderItemData.proofLastModifiedDate,"tooltip-analytics-name":"Overall order item status"}}),_c('Tooltip',{attrs:{"top":"","analytics-name":"Order item name","analytics-params":{
              type: _vm.orderItemData.isAddOn ? 'Add-On' : 'Main'
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('a',_vm._g(_vm._b({class:[
                  'text-truncate',
                  {
                    'text-decoration-none text--darken-1 grey--text':
                      _vm.orderItemData.isAddOn
                  }
                ],attrs:{"href":'https://pc.my.salesforce.com/' + _vm.orderItem.salesforceId,"target":"_blank","rel":"noopener"},domProps:{"textContent":_vm._s(_vm.orderItem.item.shortTitle)}},'a',attrs,false),on))]}},{key:"tooltip",fn:function(){return [_vm._v(" Proof ID: "+_vm._s(_vm.orderItem.proofId.substr(0, 5))+" ")]},proxy:true}])})],1),_c('VListItemSubtitle',{class:_vm.orderItemData.isAddOn
              ? 'text--darken-1 grey--text'
              : 'text--primary'},[_vm._v(" "+_vm._s(_vm.orderItem.quantity)+" "),(
              (_vm.overallStatus === 'Shipped' ||
                _vm.overallStatus === 'Delivered') &&
              _vm.orderItem.deliveryType === 'Mail & Message'
            )?[_vm._v(" Mailed ")]:(
              (_vm.overallStatus === 'Shipped' ||
                _vm.overallStatus === 'Delivered') &&
              _vm.orderItemData.shipments.length &&
              _vm.orderItemData.shipments[0].trackingUrl
            )?_c('a',{class:_vm.orderItemData.isAddOn
                ? 'text-decoration-none text--darken-1 grey--text'
                : 'text--primary',attrs:{"href":_vm.orderItemData.shipments[0].trackingUrl,"target":"_blank","rel":"noopener"},domProps:{"textContent":_vm._s(_vm.overallStatus)}}):[_vm._v(" "+_vm._s(_vm.overallStatus)+" ")],(
              _vm.overallStatus.startsWith('Pending') ||
              _vm.overallStatus === 'At Customer'
            )?[(_vm.orderItemData.proofLastModifiedDate)?[_vm._v(" • Updated: "+_vm._s(_vm.formatDateDistanceToNowIfRecent( _vm.orderItemData.proofLastModifiedDate ))+" ")]:_vm._e()]:(
              _vm.overallStatus === 'Shipped' && _vm.orderItemData.firstShipDate
            )?[_c('Tooltip',{attrs:{"top":"","analytics-name":"Ship date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formatDateDistanceToNowIfRecent( _vm.orderItemData.firstShipDate ))+" ")])]}},{key:"tooltip",fn:function(){return [_vm._v(" First Shipped: "+_vm._s(_vm.formatDateDistanceToNowIfRecent( _vm.orderItemData.firstShipDate ))),_c('br'),(
                    _vm.orderItemData.promisedShipDate.getTime() !==
                    _vm.orderItemData.adjustedPromisedShipDate.getTime()
                  )?[_vm._v(" Promised (adjusted for edits): "+_vm._s(_vm.formatDateDistanceToNowIfRecent( _vm.orderItemData.adjustedPromisedShipDate ))),_c('br')]:_vm._e(),_vm._v(" Promised: "+_vm._s(_vm.formatDateDistanceToNowIfRecent( _vm.orderItemData.promisedShipDate ))+" ")]},proxy:true}])})]:_vm._e()],2),_c('VListItemSubtitle',[_c('IconsWithTooltips',{attrs:{"icons":_vm.icons,"tooltip-analytics-name":"Order item icon"}})],1)],1),(_vm.proofUrl)?_c('VImg',{attrs:{"src":_vm.proofUrl,"contain":"","max-width":"50","max-height":"50"}}):_vm._e()],1)]),(_vm.actions.length)?_c('VListItemAction',{staticClass:"ml-1 mr-n1"},[_c('VMenu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var attrs = ref.attrs;
                  var on = ref.on;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("fas fa-ellipsis-v")])],1)]}}],null,false,707099145)},[_c('VList',_vm._l((_vm.actions),function(action,index){return _c('VListItem',{key:index,attrs:{"href":action.link,"target":"_blank","dense":""}},[_vm._v(" "+_vm._s(action.title)+" ")])}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }