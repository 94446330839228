




























import {
  defineComponent,
  PropType,
  Ref,
  ref,
  watch
} from '@vue/composition-api'
import { differenceInCalendarDays } from 'date-fns'

import AppHeading from '@/components/AppHeading.vue'
import {
  trackToggleAddOns,
  trackTogglePanel
} from '@/components/mixins/analytics'
import OrderComponent from '@/components/Order.vue'
import { Order as SalesforceOrder, Proof } from '@/generated'
import { Order } from '@/store/modules/types/order'

export default defineComponent({
  components: {
    AppHeading,
    Order: OrderComponent
  },
  props: {
    orders: {
      type: Array as PropType<Order[]>,
      required: true
    },
    salesforceOrders: {
      type: Array as PropType<SalesforceOrder[]>,
      required: true
    },
    proofs: {
      type: Array as PropType<Proof[]>,
      required: true
    }
  },
  setup({ orders, salesforceOrders }) {
    const openPanels: Ref<number[]> = ref([])
    const now = new Date()
    for (const [i, order] of orders.entries()) {
      const orderDate = new Date(order.created)
      if (openPanels.value.push(i) > 3) {
        // Only open up to the first 3 orders at once
        break
      }
      const daysOld = differenceInCalendarDays(now, orderDate)
      if (daysOld > 45) {
        // Only open recent orders
        break
      }
    }
    watch(openPanels, (updatedIndices, oldIndices) => {
      trackTogglePanel(updatedIndices.length > oldIndices.length, 'Order')
    })

    const showAddOns = ref(false)
    watch(showAddOns, trackToggleAddOns)

    return {
      salesforceOrder(order: Order) {
        return salesforceOrders.find(
          (salesforceOrder) => salesforceOrder.orderNumber == order.orderNumber
        )
      },
      showAddOns,
      openPanels
    }
  }
})
