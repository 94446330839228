












































































































































import { defineComponent, PropType } from '@vue/composition-api'
import { formatDistanceToNow } from 'date-fns'
import { capitalize, sortBy } from 'lodash-es'
import { calendarToDateObj } from 'ts-force'

import AppHeading from '@/components/AppHeading.vue'
import ChipLabel from '@/components/ChipLabel.vue'
import { formatCurrency } from '@/components/mixins/currency'
import { formatDateShortWithYear } from '@/components/mixins/dateFormatters'
import { pluralize } from '@/components/mixins/pluralize'
import { ticketStatusColors } from '@/components/mixins/tickets'
import SpecialInstructionListItems from '@/components/SpecialInstructionListItems.vue'
import TicketsSummary from '@/components/TicketsSummary.vue'
import TimelineDense, { TimelineItem } from '@/components/TimelineDense.vue'
import { Account, Order as SalesforceOrder, Proof } from '@/generated'
import { Ticket as RestTicket } from '@/store/modules/types/zendeskRest'
import Ticket = ZAFClient.Ticket

export default defineComponent({
  components: {
    SpecialInstructionListItems,
    AppHeading,
    ChipLabel,
    TicketsSummary,
    TimelineDense
  },
  props: {
    account: {
      type: Object as PropType<Account>,
      default: null
    },
    salesforceOrders: {
      type: Array as PropType<SalesforceOrder[]>,
      required: true
    },
    proofs: {
      type: Array as PropType<Proof[]>,
      required: true
    },
    ticket: {
      type: Object as PropType<Ticket>,
      required: true
    },
    tickets: {
      type: Array as PropType<RestTicket[]>,
      required: true
    }
  },
  setup({ account, tickets, proofs }) {
    let customerTimelineItems: TimelineItem[] = []

    if (account) {
      if (
        account.emailCollectionDate &&
        (!account.registrationDate ||
          account.emailCollectionDate.getTime() !==
            account.registrationDate.getTime())
      ) {
        customerTimelineItems.push({
          title: 'Signed up for emails',
          date: account.emailCollectionDate,
          icon: '$envelope'
        })
      }
      if (account.registrationDate) {
        customerTimelineItems.push({
          title: 'Registered',
          date: account.registrationDate,
          icon: '$user'
        })
      }
    }
    const addTicket = (ticket: RestTicket) =>
      customerTimelineItems.push({
        title: ticket.subject,
        caption: ticket.description,
        icon: '$question-circle',
        date: new Date(ticket.createdAt),
        color: ticketStatusColors[ticket.status].color
      })
    if (tickets.length) {
      // Add first ticket to give context for how old the customer is
      addTicket(tickets[0])
    }
    if (tickets.length >= 4) {
      // Collapse additional tickets into a single summary line
      customerTimelineItems.push({
        title: `${tickets.length - 3} tickets from ${formatDateShortWithYear(
          tickets[1].createdAt
        )}…`,
        date: new Date(tickets[tickets.length - 3].createdAt),
        icon: '$question-circle',
        color: ticketStatusColors['closed'].color
      })
    } else if (tickets.length == 4) {
      addTicket(tickets[tickets.length - 3])
    }
    if (tickets.length >= 3) {
      // Add 2nd to last ticket for context
      addTicket(tickets[tickets.length - 2])
    }
    if (tickets.length >= 2) {
      // Add last (most recent) ticket for context
      addTicket(tickets[tickets.length - 1])
    }
    customerTimelineItems = sortBy(customerTimelineItems, (item) => item.date)
    const firstSeen = customerTimelineItems[0]?.date
    let cartCount = 0
    let savedCount = 0
    let canceledCount = 0
    proofs.forEach((proof) => {
      // Don't count add-ons
      if (proof.proofId === proof.project?.projectId) {
        if (proof.status == 'Cancelled') {
          canceledCount++
        } else if (proof.status == 'Saved') {
          savedCount++
        } else if (proof.paid === false) {
          cartCount++
        }
      }
    })

    interface Rating {
      date: Date
      source: 'Zendesk' | 'Delighted' | 'Negative'
      score: 'good' | 'bad' | number
      name?: string
      comment?: string
    }

    const isRatingGood = (rating: Rating) =>
      rating.score === 'good' || rating.score >= 9
    const isRatingBad = (rating: Rating) =>
      rating.score === 'bad' || rating.score <= 6
    const isRatingMeh = (rating: Rating) =>
      !isRatingGood(rating) && !isRatingBad(rating)
    let ratings = [] as Rating[]
    tickets.forEach((ticket) => {
      if (
        ticket.satisfactionRating.score == 'good' ||
        ticket.satisfactionRating.score == 'bad'
      ) {
        ratings.push({
          date: new Date(ticket.createdAt),
          source: 'Zendesk',
          score: ticket.satisfactionRating.score,
          comment:
            (ticket.satisfactionRating.reason !== 'No reason provided' &&
              ticket.satisfactionRating.reason) ||
            undefined
        })
      }
    })
    account.delightedResponses?.forEach((delightedResponse) => {
      // Both timestamp and score will always be defined based on our query and schema
      if (delightedResponse.timestamp && delightedResponse.score) {
        ratings.push({
          date: new Date(delightedResponse.timestamp),
          score: delightedResponse.score,
          source: 'Delighted',
          comment: delightedResponse.comment || undefined
        })
      }
    })
    account.negatives?.forEach((negative) => {
      // Both createdDate and name will always be defined based on our query and schema
      if (negative.createdDate && negative.name) {
        ratings.push({
          date: new Date(negative.createdDate),
          score: 'bad',
          source: 'Negative',
          name:
            negative.name +
            (negative.category ? ` (${negative.category})` : ''),
          comment: negative.description || undefined
        })
      }
    })
    ratings = sortBy(ratings, (rating) => rating.date.getTime())
    const ratingsTimelineItems = ratings.map((rating) => {
      let title
      switch (rating.source) {
        case 'Delighted':
          title = `${rating.score}/10 NPS score from Delighted`
          break
        case 'Negative':
          title = `${rating.name} from customer negative`
          break
        case 'Zendesk':
          title = `${capitalize(
            rating.score as string
          )} rating from Zendesk satisfaction survey`
          break
      }
      let color
      let icon
      if (isRatingGood(rating)) {
        color = 'green'
        icon = '$smile'
      } else if (isRatingBad(rating)) {
        color = 'red'
        icon = '$frown'
      } else {
        color = 'orange'
        icon = '$meh'
      }
      return {
        title,
        caption: rating.comment,
        color,
        date: rating.date,
        icon
      } as TimelineItem
    })

    return {
      calendarToDateObj,
      customerTimelineItems,
      firstSeen,
      formatCurrency,
      formatDateShortWithYear,
      formatDistanceToNow,
      pluralize,
      cartCount,
      savedCount,
      canceledCount,
      ratings,
      ratingsTimelineItems,
      isRatingGood,
      isRatingBad,
      isRatingMeh
    }
  }
})
