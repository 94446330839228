import { library } from '@fortawesome/fontawesome-svg-core'
import { faSalesforce } from '@fortawesome/free-brands-svg-icons'
import {
  faAddressCard,
  faCashRegister,
  faCheckSquare,
  faChevronDown,
  faComments,
  faCut,
  faEllipsisV,
  faEnvelope,
  faFastForward,
  faFighterJet,
  faFrown,
  faHome,
  faInfoCircle,
  faLayerGroup,
  faLayerPlus,
  faMailBulk,
  faMeh,
  faPenSquare,
  faPlane,
  faPrint,
  faQuestionCircle,
  faRedo,
  faRingsWedding,
  faSave,
  faSearch,
  faShippingFast,
  faShoppingCart,
  faSmile,
  faSparkles,
  faSquare,
  faStickyNote,
  faTimesCircle,
  faTrash,
  faTruck,
  faUser,
  faUserClock,
  faUserCrown
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue from 'vue'

// Set up Font Awesome with tree shaking
// https://stackoverflow.com/questions/52030435/fontawesome-with-vuetify-how-to-include-font-awesome-icons-within-the-v-icon-c
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
library.add(
  faAddressCard,
  faCashRegister,
  faCheckSquare,
  faChevronDown,
  faComments,
  faCut,
  faEllipsisV,
  faEnvelope,
  faFastForward,
  faFighterJet,
  faFrown,
  faHome,
  faInfoCircle,
  faLayerGroup,
  faLayerPlus,
  faMailBulk,
  faMeh,
  faPenSquare,
  faPlane,
  faPrint,
  faQuestionCircle,
  faRedo,
  faRingsWedding,
  faSalesforce,
  faSave,
  faSearch,
  faShippingFast,
  faShoppingCart,
  faSmile,
  faSparkles,
  faSquare,
  faStickyNote,
  faTimesCircle,
  faTrash,
  faTruck,
  faUser,
  faUserClock,
  faUserCrown
)

const faIcon = (collection: string, name: string, key: string = name) => {
  return {
    [key]: {
      component: FontAwesomeIcon,
      props: {
        icon: [collection, name]
      }
    }
  }
}
/** Solid icon */
const fasIcon = (name: string, key: string = name) => faIcon('fas', name, key)
/** Brand icon */
const fabIcon = (name: string, key: string = name) => faIcon('fab', name, key)

/**
 * Icons can be referenced using a `$` prefix, e.g. `<VIcon>$[name]</VIcon>`
 */
export const CUSTOM_ICONS = {
  ...fabIcon('salesforce'),
  ...fasIcon('address-card'),
  ...fasIcon('cash-register'),
  ...fasIcon('check-square'),
  ...fasIcon('chevron-down'),
  ...fasIcon('comments'),
  ...fasIcon('cut'),
  ...fasIcon('ellipsis-v'),
  ...fasIcon('envelope'),
  ...fasIcon('fast-forward'),
  ...fasIcon('fighter-jet'),
  ...fasIcon('frown'),
  ...fasIcon('home'),
  ...fasIcon('info-circle'),
  ...fasIcon('layer-group'),
  ...fasIcon('layer-plus'),
  ...fasIcon('mail-bulk'),
  ...fasIcon('meh'),
  ...fasIcon('pen-square'),
  ...fasIcon('plane'),
  ...fasIcon('print'),
  ...fasIcon('question-circle'),
  ...fasIcon('rings-wedding'),
  ...fasIcon('redo'),
  ...fasIcon('save'),
  ...fasIcon('search'),
  ...fasIcon('shipping-fast'),
  ...fasIcon('shopping-cart'),
  ...fasIcon('smile'),
  ...fasIcon('sparkles'),
  // Override checkboxOff to use fas instead of far
  // https://stackoverflow.com/a/64373929/1058558
  ...fasIcon('square', 'checkboxOff'),
  ...fasIcon('sticky-note'),
  ...fasIcon('times-circle'),
  ...fasIcon('trash'),
  ...fasIcon('truck'),
  ...fasIcon('user'),
  ...fasIcon('user-clock'),
  ...fasIcon('user-crown')
}
