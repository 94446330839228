















































































import { defineComponent, PropType } from '@vue/composition-api'

import {
  formatDateDistanceToNowIfRecent,
  formatDateVerbose
} from '@/components/mixins/dateFormatters'
import { orderUtils } from '@/components/mixins/orderUtils'
import { pluralize } from '@/components/mixins/pluralize'
import SpecialInstructionListItems from '@/components/SpecialInstructionListItems.vue'
import { Order as SalesforceOrder, Proof } from '@/generated'
import { Order } from '@/store/modules/types/order'

import IconWithTooltip from './IconWithTooltip.vue'
import OrderItem from './OrderItem.vue'
import OrderItemOverallStatusIcon from './OrderItemOverallStatusIcon.vue'

export default defineComponent({
  components: {
    IconWithTooltip,
    OrderItem,
    OrderItemOverallStatusIcon,
    SpecialInstructionListItems
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true
    },
    salesforceOrder: {
      type: Object as PropType<SalesforceOrder>,
      required: true
    },
    showAddOns: {
      type: Boolean,
      required: true
    },
    proofs: {
      type: Array as PropType<Proof[]>,
      required: true
    }
  },
  setup({ order }) {
    return {
      formatDateDistanceToNowIfRecent,
      formatDateVerbose,
      pluralize,
      ...orderUtils(order),
      checkbox: (value: boolean): string => (value ? '☑' : '☐'),
      shippingType: order.shippingType?.type
    }
  }
})
