















































import { defineComponent } from '@vue/composition-api'

import { TooltipParams } from '@/components/mixins/analytics'
import { iconValidator } from '@/components/mixins/icons'
import Tooltip from '@/components/Tooltip.vue'

export default defineComponent({
  components: {
    Tooltip
  },
  props: {
    icon: {
      type: String,
      required: true,
      validator: iconValidator
    },
    color: {
      type: String,
      default: null
    },
    right: {
      type: Boolean
    },
    label: {
      type: [String, Number],
      default: null
    },
    tooltipAnalyticsName: {
      type: String,
      default: 'IconWithTooltip'
    },
    tooltipColor: {
      type: String,
      default: undefined
    }
  },
  setup({ icon, color, right }) {
    const tooltipAnalyticsParams: TooltipParams = {
      icon,
      // Only add if defined
      ...(color ? { color } : {})
    }
    return {
      tooltipAnalyticsParams,
      left: !right
    }
  }
})
